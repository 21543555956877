<template>
  <div class="container">
    Welcome
  </div>
</template>


<script>
import { mapGetters, mapActions } from 'vuex';
// import jsonApi from '../../../swagger.json'
// import OpenApi from 'vue-openapi'
// import SwaggerUI from 'swagger-ui';
// import 'swagger-ui/dist/swagger-ui.css';
import jwt from "jsonwebtoken";

export default {
  name: "Swagger",
  mounted() {
          var body = {
              "email": this.userObject.attributes.email,
              "partnername": this.userObject.attributes['custom:domainId']
          }

          console.log("The body is, ofcourse:")
          console.log(body)

          this.fetchAccessibleDocs(body).then(response => {
            console.log("RESPONSE")
            console.log(response)
          })
  },
  data: function() {
    return {
        queryParams: {
          userId: 'john_doe'
        },
        headers: {
          api_key: 'my_api_key'
        }
    }
  },
  computed: {
      ...mapGetters('userAuthentication', ['userObject']),
      ...mapGetters('fileTracker', ['fetchAccessibleDocsFromState'])
  },
  methods: {
    ...mapActions('fileTracker', ['fetchAccessibleDocs'])
  },
  components: {
  }
}

</script>