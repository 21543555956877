<template>
  <div class="container">
    <h1>API Keys</h1>

    <table class="table is-fullwidth mt-5 fixed" style="font-size: 13.5px">
      <thead>
        <tr>
          <th style="width: 150px">Name</th>
          <th style="width: 300px">Token</th>
          <th style="width: 150px">Status</th>
          <th style="width: 150px">Valid From</th>
          <th style="width: 150px">Valid Until</th>
          <th style="width: 150px">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(log, index) in this.fetchUploadedFilesFromState"
          :key="index"
        >
          <td>
            {{ log.name }}
          </td>
          <td>
            <span style="word-wrap: break-word">
              {{ log.apiKey }}
            </span>
          </td>
          <td>
            {{ log.status }}
          </td>
          <td>
            {{ log.validSince }}
          </td>
          <td>
            {{ log.validUntil }}
          </td>
          <td>
            <button
              class="button is-small"
              v-on:click="revokeApiKey(log.api_token, index)"
              v-if="log.status == 'active'"
            >
              Revoke
            </button>
          </td>
        </tr>

        <tr>
          <td colspan="4">
            <button @click="generateNewApiKey()">Generate New API Key</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<style scoped>
table.fixed {
  table-layout: fixed;
}
table.fixed td {
  overflow: hidden;
}
</style>

<script>
import { mapGetters, mapActions } from "vuex";
import jwt from "jsonwebtoken";

export default {
  name: "Swagger",
  mounted() {
    var body = {
      email: this.userObject.attributes.email,
      partnername: this.userObject.attributes["custom:domainId"],
    };

          console.log("The body is, ofcourse:")
          console.log(body)

      this.fetchUploadedFiles(body)
      this.fetchAccessibleDocs(body)
  },
  data: function () {
    return {
      queryParams: {
        userId: "john_doe",
      },
      headers: {
        api_key: "my_api_key",
      },
    };
  },
  computed: {
    ...mapGetters("fileTracker", ["fetchUploadedFilesFromState"]),
    ...mapGetters("userAuthentication", ["userObject"]),
  },
  methods: {
    generateNewApiKey() {
      this.$router.push("/api-keys/new");
    },
    revokeApiKey(apiKey, index) {
      var body = {
        email: this.userObject.attributes.email,
        partnername: this.userObject.attributes["custom:domainId"],
      };

          console.log("The body is, ofcourse:")
          console.log(body)

      console.log("WE ARE REVOKING");
      console.log(apiKey);

        var mainData = {
            token: body,
            apiKey: apiKey,
            index: index
        }

        this.revokeApiKeyWithThisKey(mainData)

      this.revokeApiKeyWithThisKey(mainData);
      this.$forceUpdate();
    },
    ...mapActions("fileTracker", [
      "fetchUploadedFiles",
      "revokeApiKeyWithThisKey",
      "fetchAccessibleDocs",
    ]),
  },
  components: {},
};
</script>