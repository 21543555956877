import { Amplify, API } from 'aws-amplify';

export const state = {
    files: {
        results: {
            data: [],
            total: 0
        }
    },
    accessible_docs: []
}

export const getters = {
    fetchUploadedFilesFromState() {
        return state.files
    },
    fetchAccessibleDocsFromState() {
        console.log("I AM RETURNING DEM DOCS")
        console.log(state.accessible_docs)
        return state.accessible_docs
    },
}

export const namespaced = true

export const mutations = {
    async SET_TOKENS(state, tokens) {
        state.files = tokens
        console.log("AND THE TOKENS LIST IS:")
        console.log(state.files)
    },
    async DEACTIVATE_TOKEN(state, index) {
        console.log(state.files)
        console.log(state.tokens)
        console.log(state)
        console.log(index)
        state.files[index]['status'] = 'inactive'
    },
    async SET_ACCESSIBLE_DOCS(state, docs) {
        state.accessible_docs = docs
        console.log("AND THE TOKENS LIST IS:")
        console.log(state.accessible_docs)
    }
}

export const actions = {
    async fetchUploadedFiles({commit, state}, token) {
        var stringfied_token = JSON.stringify(token)

        return API.get('devPortalBackend', `/tokens`, {headers: {'Authorization': `${stringfied_token}`}}).then(response => {
            console.log("ASYNC INDEX FETCH -- DONE")
            console.log("AND THE RESPONSE IS")
            console.log(response)
            commit('SET_TOKENS', response)
        })
    },

    async fetchAccessibleDocs({commit, state}, token) {
        var stringfied_token = JSON.stringify(token)
        return API.get('devPortalBackend', `/api_docs`, {headers: {'Authorization': `${stringfied_token}`}}).then(response => {
            console.log("ASYNC INDEX FETCH -- DONE")
            console.log("AND THE RESPONSE IS")
            console.log(response)
            commit('SET_ACCESSIBLE_DOCS', response)
        })
    },

    async fetchAccessibleDocsMode2({commit, state}, token) {
        var stringfied_token = JSON.stringify(token)
        return API.get('devPortalBackend', `/api_docs`, {headers: {'Authorization': `${stringfied_token}`}})
    },

    async createToken({commit}, master_data) {

        console.log("GENERATE PRESIGNED URL")

        var stringfied_token = JSON.stringify(master_data.token)
        var payload = {"headers": {"Authorization": stringfied_token}, "body": master_data.data}
        console.log("AND OUR PAYLOAD IS")
        console.log(payload)
        const response = await API.post('devPortalBackend', `/tokens`, payload)

        // const response = await API.post('batchMiddleware', `/generate_presigned_url`, {
        //     "body": {
        //         'source_filekey': object_key
        //     }
        // })

        return response
    },

    async revokeApiKeyWithThisKey({commit}, mainData) {
        var stringfied_token = JSON.stringify(mainData.token)
        await API.del('devPortalBackend', `/tokens/${mainData.apiKey}`, {headers: {'Authorization': `${stringfied_token}`}}).then(response => {
            console.log("ASYNC INDEX FETCH -- DONE")
            console.log("AND THE RESPONSE IS")
            console.log(response)
            commit('DEACTIVATE_TOKEN', mainData.index)
        })
    }
}