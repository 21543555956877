<template>
  <div id="app">
    <div id="nav">
      <NavBar :docs="fetchAccessibleDocsFromState"> </NavBar>
    </div>
    <router-view />
    <notifications group="foo" position="bottom right"> </notifications>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Home",
  components: {
    NavBar,
  },
  computed: {
    ...mapGetters("fileTracker", ["fetchAccessibleDocsFromState"]),
  },
  methods: {
    ...mapActions("fileTracker", ["fetchAccessibleDocs"]),
  },
  mounted() {
    document.title = "SM DAC Dev Portal";
  },
};
</script>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>