

<template>
  <div class="container">
    <h1>API Keys</h1>
    <alerts
      v-show="error"
      :msg="error_msg"
      :conforms="comforms"
      @alert_close="parent_close"
    />

    <div class="field">
      <label class="label has-text-left"> Name </label>
      <div class="control">
        <input
          class="input"
          type="text"
          placeholder="Text input"
          v-model="data['name']"
          required
        />
      </div>
    </div>

    <div class="field">
      <label class="label has-text-left"> Valid Until </label>
      <div class="control">
        <input
          class="input"
          type="date"
          placeholder="Text input"
          v-model="data['validUntil']"
          :disabled="enableValidUntil"
          required
        />
      </div>
    </div>
    <div class="field">
      <label class="label has-text-left"> Never Expire? </label>
      <div class="control">
        <input type="checkbox" v-model="enableValidUntil" />
      </div>
    </div>
    <div class="field is-grouped is-centered">
      <div class="control">
        <button class="button is-link" v-on:click="submitForm">Submit</button>
      </div>
      <router-link :to="'/api-keys'"> Cancel </router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import jwt from "jsonwebtoken";
import alerts from "@/components/alerts.vue";
import dayjs from "dayjs";
export default {
  data: function () {
    return {
      data: {},
      email: "",
      password: "",
      code: "",
      showOtp: false,
      showFirstSignInPanel: false,
      new_password: "",
      comforms: false,
      error: false,
      error_msg: "",
      enableValidUntil: true,
    };
  },
  computed: {
    ...mapGetters("userAuthentication", ["userObject"]),
  },
  methods: {
    parseDatePickerValue(datePickerValue) {
      this.data['validUntil'] = dayjs(datePickerValue, "YYYY-MM-DD").format("YYYY/MM/DD");
    },
    parent_close(resp) {
      this.error = resp;
    },
    async submitForm() {
      console.log("we have this");
      console.log(this.data);
      console.log("user object");
      console.log(this.userObject);

      this.data["status"] = "active";
      this.data["never_expire"] = this.enableValidUntil;
      this.data['validUntil'] = dayjs(this.data['validUntil'], "YYYY-MM-DD").format("YYYY/MM/DD");
      var body = {
        email: this.userObject.attributes.email,
        partnername: this.userObject.attributes["custom:domainId"],
      };

      console.log("The body is, ofcourse:");
      console.log(body);

      var master_data = {
        token: body,
        data: this.data,
      };

      this.createToken(master_data).then((response) => {
        console.log("THIS IS A SUCCESS");
        console.log(response);

        if (response.error === true) {
          console.log("I JATE ");
          console.log("The date entered is already past");
          this.error = true;
          this.email = "";
          this.password = "";
          this.error_msg = response.message;
        } else {
          this.$router.push("/api-keys");
        }
      });
    },
    ...mapActions("fileTracker", ["createToken"]),
  },
  components: { alerts },
};
</script>