<template>
  <div class="notification is-danger">
    <button class="delete" @click="alert_close"></button>
    <!-- <button class="delete"></button> -->
    {{msg}}
    <ul v-show="conforms">
              <li>Lowercase Letter</li>
              <li>Uppercase Letter</li>
              <li>Number</li>
              <li>Symbol</li>
            </ul>
  </div>
</template>

<script>
export default {
    props: ['msg', 'conforms'],
  methods: {
    alert_close(event) {
      this.$emit("alert_close", false);
    },
  },
};
</script>
