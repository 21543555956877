const awsconfig = {
  Auth: {
    region: "ap-southeast-1",
    identityPoolId: "ap-southeast-1:b92c139d-e04c-4a78-8674-3d37ef450c7d",
    userPoolId: "ap-southeast-1_yeqFvDeia",
    userPoolWebClientId: "4g0b7ddqldjqll6bbfs47kig5i"
  },
  API: {
    endpoints: [
      {
        name: "devPortalBackend",
        endpoint: "https://lli9ofg1xf.execute-api.ap-southeast-1.amazonaws.com/dev",
        region: "ap-southeast-1"
      }
    ]
  }
};

export default awsconfig;
