<template>
  <section>
    <div class="columns">
      <div class="column is-one-third"></div>
      <div class="column is-one-third">
        <!-- <amplify-authenticator>
                <amplify-sign-out></amplify-sign-out>
            </amplify-authenticator> -->

        <alerts v-show="error" :msg="error_msg" :conforms="comforms" @alert_close="parent_close" />

        <form class="box" @submit.prevent="login" v-show="!showOtp">
          <div class="field">
            <label class="label">Email</label>
            <div class="control">
              <input
                class="input"
                v-model="email"
                type="email"
                placeholder="e.g. alex@example.com"
              />
            </div>
          </div>

          <div class="field">
            <label class="label">Password</label>
            <div class="control">
              <input
                class="input"
                v-model="password"
                type="password"
                placeholder="********"
              />
            </div>
          </div>

          <button class="button is-primary">Sign in</button>
        </form>

        <form
          class="box"
          @submit.prevent="submitOtp"
          v-show="showOtp && userObject.challengeName === 'SMS_MFA'"
        >
          <div class="field">
            <label class="label">One Time Password</label>
            <div class="control">
              <input class="input" v-model="code" placeholder="e.g. 123456" />
            </div>
          </div>

          <button class="button is-primary">Sign in</button>
        </form>

        <!-- first sign in -->

        <form
          class="box"
          @submit.prevent="submitNewPassword"
          v-show="
            showFirstSignInPanel &&
            userObject.challengeName === 'NEW_PASSWORD_REQUIRED'
          "
        >
          <div class="field">
            <label class="label">New Password</label>
            Please ensure your new password conforms to our password policy:
            <ul>
              <li>Lowercase Letter</li>
              <li>Uppercase Letter</li>
              <li>Number</li>
              <li>Symbol</li>
            </ul>
            <div class="control">
              <input
                class="input"
                v-model="new_password"
                placeholder="e.g. 123456"
              />
            </div>
          </div>

          <button class="button is-primary">Sign in</button>
        </form>

        <form
          class="box"
          @submit.prevent="redirectToHomePage"
          v-show="showOtp && userObject.challengeName !== 'SMS_MFA'"
        >
          <div class="field">
            <label class="label">Welcome to SM DAC API Developer's Portal</label>
          </div>

          <button class="button is-primary">Let's go</button>
        </form>
      </div>
      <div class="column is-one-third"></div>
    </div>
  </section>
</template>

<script>
// document.addEventListener("DOMContentLoaded", () => {
//   (document.querySelectorAll(".notification .delete") || []).forEach(
//     ($delete) => {
//       const $notification = $delete.parentNode;

//       $delete.addEventListener("click", () => {
//         $notification.parentNode.hide();
//       });
//     }
//   );
// });
import { mapGetters, mapActions } from "vuex";
import alerts from "@/components/alerts.vue";
// import NavBar from "@/components/NavBar.vue";
// import Alerts from "../../components/alerts.vue";

export default {
  name: "Login",
  data() {
    return {
      email: "",
      password: "",
      code: "",
      showOtp: false,
      showFirstSignInPanel: false,
      new_password: "",
      comforms: false,
      error: false,
      error_msg: ""
    };
  },
  computed: {
    ...mapGetters("userAuthentication", ["userObject"]),
  },
  methods: {
    redirectToHomePage() {
      this.$router.push({
        name: "DashboardIndex",
      });
    },
    parent_close(resp) {
      this.error = resp;
    },
    async submitOtp() {
      this.confirmSigninViaOtp({
        user: this.userObject,
        code: this.code,
        mfaType: "SMS_MFA",
      }).then(() => {
        this.showOtp = true;
      });
    },
    async submitNewPassword() {
      console.log("SUBMIT NEW PASSWORD");
      console.log(this.new_password);
      console.log("MANIPULATE USER OBJECT");
      console.log(this.userObject);
      this.firstSigninPasswordSet({
        user: this.userObject,
        newPassword: this.new_password,
      })
        .then((error) => {
          this.showOtp = true;
          console.log(error);
        })
        .catch((err) => {
          console.log(err.message);
          // this.new_password = '';
          this.error = true;
          this.comforms = true;
          this.error_msg = err.message;
          // this.showOtp = false;
        });
    },
    async login() {
      // try {
      //     const user = await Auth.signIn(this.email, this.password);
      //     console.log(user)
      //     alert('Successfully logged in');
      // } catch (error) {
      //     alert(error.message);
      // }
      this.userLogin({ email: this.email, password: this.password })
        .then((user) => {
          // this.$router.push({
          //     name: 'RegistriesIndex'
          // })
          console.log("MANIPULATE USER OBJECT");
          console.log(user);
          if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
            this.showFirstSignInPanel = true;
          } else {
            this.showOtp = true;
          }
        })
        .catch((err) => {
          this.error = true;
          this.email = "";
          this.password = "";
          this.error_msg = "Incorrect email or password. please try again"
        });
    },
    ...mapActions("userAuthentication", [
      "userLogin",
      "confirmSigninViaOtp",
      "verifyUserSessionValid",
      "firstSigninPasswordSet",
    ]),
  },
  components: { alerts },
};
</script>
