import Vue from "vue";
import VueRouter from "vue-router";

import UserSignin from "../views/users/Signin.vue";
import DashboardIndex from '../views/dashboard/dashboardIndex'
import ApiKeyIndexPage from '../views/apiKeys/apiKeyIndexPage'
import NewApiKeyPage from '../views/apiKeys/newApiKeyPage'
import ApiDocShowPage from '../views/apiDocs/apiDocsShowPage'


Vue.use(VueRouter);

const routes = [
  {
    path: "/dashboard",
    name: "DashboardIndex",
    component: DashboardIndex,
    meta: { requiresAuth: true }
  },
  {
    path: "/api-keys",
    name: "ApiKeyIndexPage",
    component: ApiKeyIndexPage,
    meta: { requiresAuth: true }
  },
  {
    path: "/api-keys/new",
    name: "ApiKeyIndexPage",
    component: NewApiKeyPage,
    meta: { requiresAuth: true }
  },
  {
    path: "/users/signin",
    name: "UserSignin",
    component: UserSignin,
    meta: { requiresAuth: false }
  },
  {
    path: "/api-docs/:apiDocName",
    name: "ApiDocShowPage",
    component: ApiDocShowPage,
    meta: { requiresAuth: true },
    props: true
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeResolve(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    
    try {
      await Vue.prototype.$Amplify.Auth.currentAuthenticatedUser();
      next();
    } catch (e) {
      console.log("AND THE ERROR IS:")
      console.log(e)

      next({
        path: "/",
        query: {
          redirect: to.fullPath
        }
      });
    }

    // Auth.currentAuthenticatedUser().then((user) => {
    //   console.log('user email = ' + user.attributes.email);
    // }).catch((err)=> {
    //   console.log(err)
    //   next({
    //     path: "/",
    //     query: {
    //       redirect: to.fullPath
    //     }
    //   });
    // });
  }
  next();
});

export default router;
