import Vue from 'vue'

import Vuex from 'vuex'
// import BackendService from '../services/BackendService'

import * as userAuthentication from './modules/user_authentication.js'
import * as fileTracker from './modules/file_tracker.js'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    userAuthentication,
    fileTracker,
  },
  state: {
    email: ''
  },
  getters: {},
  mutations: {},
  actions: {
    // USERS
  }
})
