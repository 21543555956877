<template>
  <div class="container" style="height: '1000px'">
    Welcome {{ this.apiDocName }}

    <!-- {{this.htmlFile}} -->

    <!-- <div v-html="htmlFile" />  -->
    
    <!-- <div class="container" v-html="htmlFile">
    </div> -->
    <!-- <div class="swagger" id="swagger"></div> -->

    <iframe
            :src=this.htmlFile
            style="width: 0; min-width: 100%;"
            width="100%"
            frameborder="0" >
           </iframe>

    <!-- <vue-friendly-iframe :src=this.htmlFile @load="onLoad" :height="iframeHeight" width="100%"></vue-friendly-iframe> -->

  </div>
</template>

<style>
iframe:not(.md-image){
  height: 1000px
}
</style>

<script>
import { mapGetters, mapActions } from 'vuex';
import jwt from "jsonwebtoken";
// import SwaggerUI from 'swagger-ui';
//const SwaggerUI = require('swagger-ui')

export default {
  name: "Swagger",
  props: ['apiDocName'],
  mounted() {
          var body = {
              "email": this.userObject.attributes.email,
              "partnername": this.userObject.attributes['custom:domainId']
          }

          console.log("The body is, ofcourse:")
          console.log(body)

      // this.fetchAccessibleDocs(token)



      console.log("apiDocName")
      console.log(this.apiDocName)

      this.fetchAccessibleDocs(body)

      this.fetchAccessibleDocsMode2(body).then(response => {
        console.log("this.fetchAccessibleDocsFromState")
        console.log(response)

            for (var i = 0; i < response.length; i++) {
              console.log("COMPARING")
              console.log(response[i])
              console.log(response[i].header)
              console.log(this.apiDocName)
              console.log("====")

              if (response[i].header == this.apiDocName) {
                // SwaggerUI({
                //     url: response[i].doc_filepath,
                //     dom_id: '#swagger'
                // });

                this.htmlFile = response[i].doc_filepath
                break;
              }
            }
      })
  },
  data: function() {
    return {
        queryParams: {
          userId: 'john_doe'
        },
        headers: {
          api_key: 'my_api_key'
        },
        htmlFile: null,
        iframeHeight: '1000px'
    }
  },
  computed: {
      ...mapGetters('fileTracker', ['fetchAccessibleDocsFromState']),
      ...mapGetters('userAuthentication', ['userObject'])
  },
  methods: {
    generateNewApiKey(){
      this.$router.push('/api-keys/new'); 
    },
    ...mapActions('fileTracker', ['fetchUploadedFiles', 'fetchAccessibleDocs', 'fetchAccessibleDocsMode2'])
  },
  components: {
  }
}

</script>