<template>
    <b-navbar>
        <template #brand>
           <section v-show="!isAuthenticated">
                <div class="">
                    <p class="title">
                    SM DAC API Developer's Portal
                    </p>
                </div>
            </section>
        </template>
        <template #start>
            <b-navbar-item href="/dashboard" v-show="isAuthenticated">
                Dashboard
            </b-navbar-item>
            <b-navbar-item href="/api-keys" v-show="isAuthenticated">
                API Keys
            </b-navbar-item>
            <!-- <b-navbar-item href="/api-docs/OLSv7" v-show="isAuthenticated">
                OLSv7 Docs
            </b-navbar-item>
            <b-navbar-item href="/api-docs/UAG" v-show="isAuthenticated">
                UAG Docs
            </b-navbar-item> -->

            <b-navbar-item v-for="log in fetchAccessibleDocsFromState" :key="log" :href="'/api-docs/' + log['header']">
                {{ log["header"] }} Docs
            </b-navbar-item>

            <!-- THE DOCS ARE:
            {{ docs }} -->
        </template>

        <template #end>
            <b-navbar-item tag="div">
                <div class="buttons">
                    <a class="button is-light" v-on:click="logoutUser()" v-show="isAuthenticated">
                        Log out
                    </a>
                </div>
            </b-navbar-item>
        </template>
    </b-navbar>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { Auth } from 'aws-amplify';


export default {
    name: 'App',
    data() {
        return {
            userLoggedIn: false
        }
    },
    props: ['docs'],
    computed: {
        checkAuthenticated: () => {
            Auth.currentAuthenticatedUser().then((user) => {
                console.log('user email = ' + user.attributes.email);
                this.userLoggedIn = true
            }).catch((err)=> {
                console.log(err)
                this.userLoggedIn = false
            });
        },
        ...mapGetters('userAuthentication', ['isAuthenticated', 'userObject']),
        ...mapGetters('fileTracker', ['fetchAccessibleDocsFromState']),
    },
    methods: {
        async logoutUser() {
            // try {
            //     const user = await Auth.signIn(this.email, this.password);
            //     console.log(user)
            //     alert('Successfully logged in');
            // } catch (error) {
            //     alert(error.message);
            // }

            this.logout({}).then(()=> {
                this.userLoggedIn = false
                this.$router.push({
                    name: 'UserSignin'
                })
            }).catch((err)=> {
                console.log(err)
            })
        },
         ...mapActions('userAuthentication', ['logout', 'verifyUserSessionValid']),
         ...mapActions('fileTracker', ['fetchAccessibleDocs'])
    },
    mounted() {
        this.verifyUserSessionValid().then(response => {
          var body = {
              "email": this.userObject.attributes.email,
              "partnername": this.userObject.attributes['custom:domainId']
          }

          console.log("The body is, ofcourse:")
          console.log(body)

          this.fetchAccessibleDocs(body)
        })


    }
};
</script>
