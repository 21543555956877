import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";


import * as AmplifyModules from 'aws-amplify';
import { Amplify } from 'aws-amplify';

import { AmplifyPlugin } from 'aws-amplify-vue';
import awsconfig from './aws-exports'; 
import Notifications from 'vue-notification'

// import VueMaterial from 'vue-material'
// import 'vue-material/dist/vue-material.css'
import VueResource from 'vue-resource'


// Vue.use(VueMaterial)
Vue.use(VueResource)

// import into project
import VuejsDialog from "vuejs-dialog";
import VuejsDialogMixin from "vuejs-dialog/dist/vuejs-dialog-mixin.min.js"; // only needed in custom components

// include the default style
import "vuejs-dialog/dist/vuejs-dialog.min.css";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faTrashAlt, faEdit } from "@fortawesome/free-solid-svg-icons";

library.add(faTrashAlt);
library.add(faEdit);

Vue.component("font-awesome-icon", FontAwesomeIcon);

Amplify.configure(awsconfig);

import Buefy from "buefy";
import "buefy/dist/buefy.css";

Vue.config.productionTip = false;
Vue.use(Buefy);
Vue.use(AmplifyPlugin, AmplifyModules);
Vue.use(Notifications);
Vue.use(VuejsDialog);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
