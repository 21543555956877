import { Auth } from "aws-amplify";

export const namespaced = true;

export const state = {
  token: localStorage.getItem("user-token") || "",
  logged_in: false,
  cognito_user: {},
};

export const getters = {
  isAuthenticated() {
    return state.logged_in;
  },
  userObject() {
    return state.cognito_user;
  },
};

export const mutations = {
  async SAVE_USER(state, cognito_user) {
    state.logged_in = true;
    state.cognito_user = cognito_user;
  },
  async REMOVE_USER(state) {
    state.logged_in = false;
    state.cognito_user = null;
  },
};

export const actions = {
  //   userSignIn({ commit }, userData) {
  //     BackendService.signIn(userData).then(response => {
  //       console.log('AND THE TOKEN IS')
  //       console.log(response['data']['token'])
  //       userData.token = response['data']['token']
  //       commit('ADD_USERNAME_PASSWORD', userData)
  //     })
  //   }
  async userLogin({ commit }, userData) {
    const user = await Auth.signIn(userData.email, userData.password);
    console.log(user);
    commit("SAVE_USER", user);
    return user;
  },

  async firstSigninPasswordSet({ commit }, { user, newPassword }) {
    const res = Auth.completeNewPassword(
      user, // the Cognito User Object
      newPassword, // the new password
      // OPTIONAL, the required attributes
      {
        name: user.username,
      }
    );
    return res;
  },

  async confirmSigninViaOtp({ commit }, otpData) {
    Auth.confirmSignIn(
      otpData["user"], // Return object from Auth.signIn()
      otpData["code"], // Confirmation code
      otpData["mfaType"] // MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
    ).then((user) => {
      console.log(user);
      commit("SAVE_USER", user);
    });
  },

  async logout({ commit }) {
    try {
      await Auth.signOut();
      commit("REMOVE_USER");
    } catch (error) {
      // alert(error.message);
    }
  },

  async verifyUserSessionValid({ commit }) {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        console.log(user);
        commit("SAVE_USER", user);
      })
      .catch((error) => {
        console.log(error);
      });
  },

  async userRegister({ commit }, userData) {
    console.log("AND THE USER DATA IS ");
    console.log(userData);
    await Auth.signUp({
      username: userData.email,
      password: userData.password,
      attributes: {
        "custom:domain_id": userData.domain_id,
      },
    });
    commit("SAVE_USER", "");
  },
};
